import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {OrgProfileService} from './service/org-profile.service';
import {OrgProfileModal} from './modal/org-profile-modal';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBaseAddUpdateComponent} from '../../core/component/abstract-base-add-update-component';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {IBaseService} from '../../core/service/ibase-service';
import {environment} from '../../../environments/environment';
import {requiredFileType} from './required-file-type';

@Component({
    selector: 'app-org-profile',
    templateUrl: './org-profile.component.html',
    styleUrls: ['./org-profile.component.css']
})

export class OrgProfileComponent extends AbstractBaseAddUpdateComponent<OrgProfileModal> implements OnInit {
    public imgURL: string;
    public imgURL2: string;
    public message: string;
    public isAddressSame: boolean;
    public formGroup: FormGroup;
    public submitted: boolean;
    public file: Array<File> | null = null;
    public data: OrgProfileModal;

    constructor(private service: OrgProfileService,
                public formBuilder: FormBuilder,
                public router: Router,
                public toastrService: ToastrService,
                public activatedRoute: ActivatedRoute) {
        super(toastrService, router);
    }

    ngOnInit() {
        this.init();
        this.setValidation();
    }

    getService(): IBaseService<OrgProfileModal> {
        return this.service;
    }

    get f() {
        return this.formGroup.controls;
    }

    setValidation() {
        this.formGroup = this.formBuilder.group({
            companyName: ['', Validators.required],
            gstin: ['', Validators.required],
            pan: [''],
            phoneNo:['', [this.numberOnlyValidation]],
            mobileNo:['', [this.numberOnlyValidation]],
            email:['', Validators.email],
            mobileSecondNo:['', [this.numberOnlyValidation]],
            billingAddress: ['', Validators.required],
            shippingAddress: [''],
            bankName: ['', Validators.required],
            branchName: ['', Validators.required],
            ifscCode: ['', Validators.required],
            accountHolderName: ['', Validators.required],
            accountNumber: ['', Validators.required],
            headerLogo: ['', [
                requiredFileType('jpeg', 'jpg', 'png')]
            ],
            footerSign: ['',
                requiredFileType('jpeg', 'jpg', 'png')
            ],
            footerNote: [''],
        });
    }

    onSelectAddress() {
        this.isAddressSame = !this.isAddressSame;
        if (this.data.billingAddress && this.isAddressSame) {
            this.data.shippingAddress = this.data.billingAddress;
            return;
        }
        this.data.shippingAddress = undefined;
    }

    setData(data: OrgProfileModal) {
        this.data = data;
        if (this.data.footerSign || this.data.headerLogo) {
            this.getImageUrl();
        }
    }

    getData(): OrgProfileModal {
        return this.data;
    }

    public getModuleHomeUrl(): string {
        return '/module/dashboard';
    }

    public getActiveRoute(): ActivatedRoute {
        return this.activatedRoute;
    }

    public getNewModal(): OrgProfileModal {
        return new OrgProfileModal();
    }

    public getFormGroup(): FormGroup {
        return this.formGroup;
    }

    public getId(): number {
        return this.data.id;
    }

    onHeaderFileChange(files: File[]) {

        this.getImageMachine('header', 100, 100 , files);
    }

    onFooterFileChange(files1: File[]) {

        this.getImageMachine('footer', 30, 60 , files1);
    }

    getImageMachine(id, h, w, files) {
        if (files && files[0].size > 2000000) {
            this.toastrService.error('Please upload File less than 2Mb');
            return;
        }
        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = () => {
                    const height = image.height;
                    const width = image.width;
                    if (height > h || width > w) {
                        this.toastrService.error('Height and Width must not exceed: ' + h + 'x' + w );
                        return;
                    }
                    if (id === 'header') {
                        this.imgURL = undefined;
                        this.file = new Array<File>();
                        this.imgURL = e.target.result;
                        if (this.isUpdateComponent) {
                            this.service.updateHeaderLogo(files[0]).subscribe((res) => {
                                this.data.headerLogo = res.data.headerLogo;
                            });
                        }
                    }
                    if (id === 'footer') {
                        this.imgURL2 = undefined;
                        this.imgURL2 = e.target.result;
                        this.file = new Array<File>();
                        if (this.isUpdateComponent) {
                            this.service.updateFooterSign(files[0]).subscribe((res) => {
                                this.data.footerSign = res.data.footerSign;
                            });
                        }
                    }
                };
            };
            reader.readAsDataURL(files[0]);
        }
    }

    getImageUrl() {
        if (this.isUpdateComponent) {
            if (this.data.headerLogo && this.data.headerLogo.length > 0) {
                this.imgURL = environment.DOWNLOAD_GET_FILE + this.data.headerLogo;
            }
            if (this.data.footerSign) {
                this.imgURL2 = environment.DOWNLOAD_GET_FILE + this.data.footerSign;
            }
        }
    }
}
