import { BaseAddUpdateComponent } from "./base-add-update-component";
import { IBaseService } from "../service/ibase-service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ResponseCode } from "../constant/response-code";
import {FormGroup, Validators} from '@angular/forms';
import { Output, EventEmitter, Input } from "@angular/core";
import { VALIDATION_CONFIGURATION } from "src/environments/configuration";

export abstract class AbstractBaseAddUpdateComponent<T> implements BaseAddUpdateComponent<T>{

    @Output("onSaved")
    public onSavedCallback: EventEmitter<T> = new EventEmitter<T>();

    @Input("isModelComponent")
    public isModelComponent: boolean = false;

    public isUpdateComponent: boolean = false;
    public submitted: boolean = false;
    public validationConfiguration = VALIDATION_CONFIGURATION;
    public isUserAllow: boolean;
    constructor(private toastr: ToastrService, private routerSrv: Router) {

    }

    // all abstract methods for ADD-UPDATE component
    abstract getService(): IBaseService<T>;
    abstract setData(T);
    abstract getData(): T;
    abstract getModuleHomeUrl();
    abstract getActiveRoute();
    abstract getNewModal();
    abstract getFormGroup(): FormGroup;
    abstract setValidation();
    abstract getId(): number;

    init() {
        this.getActiveRoute().params.subscribe(
            (param) => {
                let id = param["id"];
                if (id) {
                    this.isUpdateComponent = true;
                    let catModal = this.getNewModal();
                    catModal.id = id;
                    this.getById(id);
                    return;
                }
                this.setData(this.getNewModal());
            }
        );
        this.setData(this.getNewModal());
        this.setValidation();
        this.authRole();
    }

    save(modal: T) {
        // console.log(JSON.stringify(modal));
        this.getService().save(modal).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                //this.setData(response.data);
                this.onSavedCallback.emit(response.data)
                this.onSaveSuccess();
            },
            (error) => {
                this.onResponseError(error.message);
            }
        );
    }

    saveWithImage(modal: T) {
        this.getService().saveWithImage(modal, this.getFile()).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                //this.setData(response.data);
                this.onSavedCallback.emit(response.data)
                this.onSaveSuccess();
            },
            (error) => {
                this.onResponseError(error.message);
            }
        );
    }

    updateWithImage(modal: T) {
        this.getService().updateWithImage(modal, this.getFile()).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                //this.setData(response.data);
                this.onSavedCallback.emit(response.data)
                this.onSaveSuccess();
            },
            (error) => {
                this.onResponseError(error.message);
            }
        );
    }

    onSave() {
        this.save(this.getData());
    }

    onSaveWithImage() {
        this.saveWithImage(this.getData());
    }
    onUpdateWithImage() {
        this.updateWithImage(this.getData());
    }
    update(modal: T) {
        // console.log(JSON.stringify(modal));
        this.getService().update(modal).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                //this.setData(response.data);
                this.onSaveSuccess();
            },
            (error) => {
                this.onResponseError(error.message);
            }
        );
    }

    onUpdate() {
        this.update(this.getData());
    }

    getById(id: number) {
        this.getService().getById(id).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setData(response.data);
            },
            (error) => {
                this.onResponseError(error.message);
            }
        );
    }

    onSubmit() {
        this.submitted = true;

        if (this.getFormGroup().invalid) {
            return;
        }

        if (this.isUpdateComponent) {
            this.onUpdate();
            return;
        }
        
        if (confirm('Are you sure you want to save this record?')) {
            this.onSave();
        }
    }

    onSubmitWithImage() {
        this.submitted = true;
        if (this.getFormGroup().invalid) {
            return;
        }

        if (this.isUpdateComponent) {
            this.onUpdateWithImage();
            return;
        }

        if (confirm('Are you sure you want to save this record?')) {
            this.onSaveWithImage();
        }
        
    }

    onCancel() {
        this.goToModuleHome();
    }

    public onSaveSuccess() {
        if (!this.isModelComponent) {
            this.goToModuleHome();
        }

        this.toastr.success("Data has been save successfully.", "Success");
    };

    public onUpdateSuccess() {
        this.toastr.success("Data has been updated successfully.", "Success");
        this.goToModuleHome();
    };

    public onResponseError(message: string) {
        this.toastr.error(message.replace('\n', '<br/>'), "Error",{enableHtml:true});
    }

    public goToModuleHome() {

        this.routerSrv.navigate([this.getModuleHomeUrl()]);
    }

    /*  public isModelComponent() : boolean{
         return false;
     } */

    public updateProfileImage(foreinKey: number, module: string, file: File) {
        this.getService().updateImage(foreinKey, module, file).subscribe(
            (response) => {
                if (response.responseCode != ResponseCode.OK) {
                    this.onResponseError(response.responseMessage);
                    return;
                }
                //this.setData(response.data);
                this.onUpdateSuccess();
            },
            (error) => {
                this.onResponseError(error);
            }
        );
    }

    public getFile(): Array<File> {
        return;
    }

    keypress(event: any) {

        const pattern = /[0-9\.]/
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
    public numberOnlyValidation  = Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/);

    public validateResponse(response, fun: Function) {
        if (response.responseCode !== ResponseCode.OK) {
            this.toastr.error(response.responseMessage,'Error');
            return;
        }

        fun();
    }

    public authRole() {
        // Id 1 for admin, Id 2 for staff, Id 3 for SuperAdmin, Id 4 for other.
        const activeUser = JSON.parse(localStorage.getItem('activeUser'));
        if (activeUser && activeUser.role && activeUser.role.roleid) {
            if (activeUser.role.roleid === 1 || activeUser.role.roleid === 3) {
                this.isUserAllow = true;
            }
            if (activeUser.role.roleid === 2 || activeUser.role.roleid === 4) {
                this.isUserAllow = false;
            }
        }
    }
}
