import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../core/util/auth-utils.service";
import {Observable} from "rxjs";
import {ResponseData} from "../../core/modal/base-save-update-response";
import {environment} from "../../../environments/environment";
import {PreInwardModal} from "../../modules/pre-inward/modal/pre-inward-modal";
import {PartyModal} from "../../modules/party/modal/party-modal";
import {PurchaseOrderModal} from "../../modules/purchase-order/modal/purchase-order";
import {SalesOrderModal} from "../../modules/sales-order/modal/sales-order-modal";
import {PHModal} from "../../modules/ph/modal/phmodal";
import {MoistureModal} from "../../modules/moisture/modal/moisture-modal";
import {SolubilityModal} from "../../modules/solubility/modal/solubility-modal";
import {Puritymodal} from "../../modules/purity/modal/puritymodal";
import {ProductModal} from "../../modules/product/modal/product-modal";
import {ProcessModel} from "../../modules/process/model/process.model";
import {CostHeadModel} from "../../modules/cost-head/model/cost-head.model";
import {DispatchDetailsModal} from "../../modules/dispatch/modal/dispatch-details-modal";
import {SupportingMaterial} from "../../modules/supporting-material/modal/supporting-material";
import {DepartmentModal} from "../../modules/department/modal/department-modal";
import {MachineModal} from "../../modules/machine/modal/machine-modal";
import { OrgProfileModal } from 'src/app/modules/org-profile/modal/org-profile-modal';
import {CustomModuleField} from "../../modules/custom-module-field/model/custom-module-field";
import {ProductTypeModel} from "../../modules/product-type/model/product-type.model";
import {ServicesModal} from "../../modules/services/modal/services-modal";
import {CrmActivity} from '../../modules/crm-activity/modal/crm-activity';
import {UserModal} from '../../modules/user/modal/user-modal';
import {FunnelStageModel} from '../../modules/funnel-stage/model/funnel-stage-model';
import {FunnelModal} from '../../modules/funnel/modal/funnel-modal';
import {CustomFieldModel} from '../../modules/custom-field/modal/custom-field-model';
import {map} from 'rxjs/operators';
import {TransportModel} from '../../modules/transport/model/transport-model';
import {CustomParameterModel} from "../../modules/custom-parameters/models/custom-parameter.model";
import {PartCodeModel} from '../../modules/part-code/model/part-code-model';
import {VendorCodeRequestResponseModel} from "../../modules/inward/modal/vendor-code-request-response.model";
import {PartyWiseVendorCodeModel} from "../../modules/inward/modal/party-wise-vendor-code.model";
import {PackingModel} from '../../modules/packing/model/packing-model';
import { ActivityLogReportModel } from 'src/app/modules/report/modal/AuditLogReport/activity-log-report-model';
import {NewPartyModel} from "../../modules/new-party/model/new-party-model";
import {AssetTypeModel} from "../../modules/asset-type/model/asset-type-model";
import {GodownModel} from "../../modules/godown/model/godown-model";
import {AssetModel} from "../../modules/asset/model/asset-model";
import {ContractStatusModel} from "../../modules/contract-status/model/contract-status-model";
import {CurrentLegalStatusModel} from "../../modules/current-legal-status/model/current-legal-status-model";
import {ActivityModel} from "../../modules/activity/model/activity-model";
import {ContractTypeModel} from "../../modules/contract-type/model/contract-type-model";
import {CompanyModel} from "../../modules/company/model/company-model";
import {BusinessAssociateModel} from "../../modules/business-associate/model/business-associate-model";
import {BusinessAssociatePersonModel} from "../../modules/business-associate-person/model/business-associate-person-model";
import {BranchModel} from "../../modules/branch/model/branch-model";
import {TransactionModel} from "../../modules/transaction-mode/model/transaction-model";

@Injectable({
  providedIn: 'root'
})
export class CommonSelectListService {
  public getAllByPartner = '/getAllByPartner';
  private sortAlphabatically = (a, b) => {
  const textA = (a.name ? a.name.toUpperCase() : '');
  const textB = (b.name ? b.name.toUpperCase() : '');
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }

  constructor(
      private http: HttpClient,
      private authService: AuthUtilsService,
  ) { }

  public getPreInwardDetail(): Observable<ResponseData<PreInwardModal>> {
    return this.http.get<ResponseData<PreInwardModal>>(environment.SERVER_URL + 'chemical/preinward' + this.getAllByPartner);
  }

  public getPartyList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.companyName ? a.companyName.toUpperCase() : '');
                  const textB = (b.companyName ? b.companyName.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getPoList(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(environment.SERVER_URL + 'chemical/purchaseOrder' + this.getAllByPartner);
  }

  public getSoList(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder' + this.getAllByPartner);
  }

  public getPhList(): Observable<ResponseData<PHModal>> {
    return this.http.get<ResponseData<PHModal>>(environment.SERVER_URL + 'chemical/ph' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getMoistureList(): Observable<ResponseData<MoistureModal>> {
    return this.http.get<ResponseData<MoistureModal>>(environment.SERVER_URL + 'chemical/moisture' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getSolubility(): Observable<ResponseData<SolubilityModal>> {
    return this.http.get<ResponseData<SolubilityModal>>(environment.SERVER_URL + 'chemical/solubility' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getPurityList(): Observable<ResponseData<Puritymodal>> {
    return this.http.get<ResponseData<Puritymodal>>(environment.SERVER_URL + 'chemical/purity' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getProductList(): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getProcessList(): Observable<ResponseData<ProcessModel>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/process' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getCostHeadList(): Observable<ResponseData<CostHeadModel>> {
    return this.http.get<ResponseData<CostHeadModel>>(environment.SERVER_URL + 'chemical/costHead' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getProduct(): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.get<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/itemStock')
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: DispatchDetailsModal[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.item ? a.item.name.toUpperCase() : '');
                  const textB = (b.item ? b.item.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getProductTypeList(): Observable<ResponseData<ProductTypeModel>> {
    return this.http.get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'chemical/productType' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getSupportingMaterialList(): Observable<ResponseData<SupportingMaterial>> {
    return this.http.get<ResponseData<SupportingMaterial>>(environment.SERVER_URL + 'chemical/supportingMaterial' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getSupplierList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>( environment.SERVER_URL + 'party/byType/2')
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.companyName ? a.companyName.toUpperCase() : '');
                  const textB = (b.companyName ? b.companyName.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getNewPartyList(): Observable<ResponseData<NewPartyModel>> {
    return this.http.get<ResponseData<NewPartyModel>>( environment.SERVER_URL + 'party' + this.getAllByPartner )
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: NewPartyModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

    public getBusinessAssociateList(): Observable<ResponseData<BusinessAssociateModel>> {
        return this.http.get<ResponseData<BusinessAssociateModel>>( environment.SERVER_URL + 'nbfc/businessAssociateType' + this.getAllByPartner )
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: BusinessAssociateModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

    public getBusinessAssociatePersonList(): Observable<ResponseData<BusinessAssociatePersonModel>> {
        return this.http.get<ResponseData<BusinessAssociatePersonModel>>( environment.SERVER_URL + 'nbfc/businessAssociatePerson' + this.getAllByPartner )
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: BusinessAssociatePersonModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

    public getContractTypeList(): Observable<ResponseData<ContractTypeModel>> {
        return this.http.get<ResponseData<ContractTypeModel>>( environment.SERVER_URL + 'nbfc/contractType' + this.getAllByPartner )
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: ContractTypeModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

    public getCompanyList(): Observable<ResponseData<CompanyModel>> {
        return this.http.get<ResponseData<CompanyModel>>( environment.SERVER_URL + 'nbfc/company' + this.getAllByPartner )
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: CompanyModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

  public getAssetList(): Observable<ResponseData<AssetModel>> {
    return this.http.get<ResponseData<AssetModel>>( environment.SERVER_URL + 'nbfc/asset' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: AssetModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }


  public getAssetTypeList(): Observable<ResponseData<AssetTypeModel>> {
    return this.http.get<ResponseData<AssetTypeModel>>( environment.SERVER_URL + 'nbfc/assetType' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: AssetTypeModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }
    public getBranchList(): Observable<ResponseData<BranchModel>> {
        return this.http.get<ResponseData<BranchModel>>( environment.SERVER_URL + 'nbfc/caseBranch' + this.getAllByPartner )
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: BranchModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

  public getGoDownList(): Observable<ResponseData<GodownModel>> {
    return this.http.get<ResponseData<GodownModel>>( environment.SERVER_URL + 'nbfc/goDown' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: GodownModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getContractStatusList(): Observable<ResponseData<ContractStatusModel>> {
    return this.http.get<ResponseData<ContractStatusModel>>( environment.SERVER_URL + 'nbfc/contractStatus' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: ContractStatusModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getCurrentLegalStatusList(): Observable<ResponseData<CurrentLegalStatusModel>> {
    return this.http.get<ResponseData<CurrentLegalStatusModel>>( environment.SERVER_URL + 'nbfc/currentLegalStatus' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: CurrentLegalStatusModel[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.name ? a.name.toUpperCase() : '');
                  const textB = (b.name ? b.name.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

    public getActivityList(): Observable<ResponseData<ActivityModel>> {
        return this.http.get<ResponseData<ActivityModel>>( environment.SERVER_URL + 'nbfc/activity' + this.getAllByPartner)
            .pipe(
                map((response) => {
                    if (response && response.dataList && response.dataList.length !== 0) {
                        let data: ActivityModel[] = JSON.parse(JSON.stringify(response.dataList));
                        data = data.sort((a, b) => {
                            const textA = (a.name ? a.name.toUpperCase() : '');
                            const textB = (b.name ? b.name.toUpperCase() : '');
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        response.dataList = data;
                        return response;
                    }
                })
            );
    }

  public getCustomerList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>( environment.SERVER_URL + 'party/byType/' + 1)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
                data = data.sort((a, b) => {
                  const textA = (a.companyName ? a.companyName.toUpperCase() : '');
                  const textB = (b.companyName ? b.companyName.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                response.dataList = data;
                return response;
              }
            })
        );
  }

  public getDepartmentList(): Observable<ResponseData<DepartmentModal>> {
    return this.http.get<ResponseData<DepartmentModal>>( environment.SERVER_URL + 'department' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }


    public getModeList(): Observable<ResponseData<TransactionModel>> {
        return this.http.get<ResponseData<TransactionModel>>( environment.SERVER_URL + 'nbfc/transactionMode' + this.getAllByPartner)
            .pipe(
                map((response) => this.sortFunction(response))
            );
    }


    public getMachineList(): Observable<ResponseData<MachineModal>> {
    return this.http.get<ResponseData<MachineModal>>( environment.SERVER_URL + 'chemical/machine' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getOrganizationData(): Observable<ResponseData<OrgProfileModal>> {
    return this.http.get<ResponseData<OrgProfileModal>>(environment.SERVER_URL + 'organization' + '/getOrgByPartner');
  }

  public getDynamicFieldConfig(reqObj): Observable<ResponseData<CustomModuleField>> {
    return this.http.post<ResponseData<CustomModuleField>>(environment.SERVER_URL + 'moduleCustomField/getCustomFields', reqObj);
  }

  public getServicesList(): Observable<ResponseData<ServicesModal>> {
    return this.http.get<ResponseData<ServicesModal>>(environment.SERVER_URL + 'chemical/services' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  public getBOMList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'chemical/bom' + this.getAllByPartner);
  }

  public getCrmActivityList(): Observable<ResponseData<CrmActivity>> {
    return this.http.get<ResponseData<CrmActivity>>(environment.SERVER_URL + 'crm/activity' + this.getAllByPartner);
  }

  public getStaffList(): Observable<ResponseData<UserModal>> {
    return this.http.get<ResponseData<UserModal>>(environment.SERVER_URL + 'staff' + this.getAllByPartner)
        .pipe(
            map((response) => {
              if (response && response.dataList && response.dataList.length !== 0) {
                response.dataList = response.dataList.sort((a, b) => {
                  const textA = (a.firstname ? a.firstname.toUpperCase() : '');
                  const textB = (b.firstname ? b.firstname.toUpperCase() : '');
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                return response;
              }
            })
        );
  }

  public getFunnelStage(): Observable<ResponseData<FunnelStageModel>> {
    return this.http.get<ResponseData<FunnelStageModel>>(environment.SERVER_URL + 'crm/funnelStage' + this.getAllByPartner);
  }

  public getFunnelList(): Observable<ResponseData<FunnelModal>> {
    return this.http.get<ResponseData<FunnelModal>>(environment.SERVER_URL + 'crm/funnel/getFunnelByLoggedInUser');
  }

  public getCustomFieldList(): Observable<ResponseData<CustomFieldModel>> {
    return this.http.get<ResponseData<CustomFieldModel>>(environment.SERVER_URL + 'customField' + this.getAllByPartner);
  }

  public getTransportList(): Observable<ResponseData<TransportModel>> {
    return  this.http.get<ResponseData<TransportModel>>(environment.SERVER_URL + 'chemical/transport' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  getProductCodes(): Observable<ResponseData<PartCodeModel>> {
    return this.http.get<ResponseData<PartCodeModel>>(environment.SERVER_URL + 'chemical/productCodeConfig' + this.getAllByPartner);
  }

  public getCustomParams(): Observable<ResponseData<CustomParameterModel>> {
    return this.http.get<ResponseData<CustomParameterModel>>(environment.SERVER_URL + 'customParam' + this.getAllByPartner);
  }

  public sortFunction(response) {
    if (response && response.dataList && response.dataList.length !== 0) {
      let data = JSON.parse(JSON.stringify(response.dataList));
      data = data.sort(this.sortAlphabatically);
      response.dataList = data;
      return response;
    }
  }

  public getDailyProductionModel(): Observable<ResponseData<{ shift: Array<string>, breakDownReason: Array<string>}>> {
    return this.http.get<ResponseData<{ shift: Array<string>, breakDownReason: Array<string>}>>(environment.SERVER_URL + 'chemical/dailyProduction/getDailyProductionModel');
  }

  getItemSpecification(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/getItemSpecification', data);
  }

  getVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/getVendorCodeForInward', data);
  }

  checkVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/checkVendorCode', data);
  }

  getCodeForVendor(partyId: number): Observable<ResponseData<PartyWiseVendorCodeModel>> {
    return this.http.get<ResponseData<PartyWiseVendorCodeModel>>(environment.SERVER_URL + `chemical/inward/getCodeForVendor/${partyId}`);
  }

  public getAuditRequestList(): Observable<ResponseData<ActivityLogReportModel>>{
    return this.http.get<ResponseData<ActivityLogReportModel>>(environment.SERVER_URL + 'chemical/activityLog/getActivityLogReportModel');
  }


  public getPackingList(): Observable<ResponseData<PackingModel>> {
    return this.http.get<ResponseData<PackingModel>>(environment.SERVER_URL + 'chemical/packing' + this.getAllByPartner).pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            response.dataList = response.dataList.sort((a, b) => {
              const textA = (a.packingType ? a.packingType.toUpperCase() : '');
              const textB = (b.packingType ? b.packingType.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            return response;
          }
        })
    );
  }

}
