export const VALIDATION_CONFIGURATION = {
  maxlengthZip: 6,
  maxlengthText: 255,
  integer: '^[0-9]*$',
  float: '^[+-]?([0-9]*[.])?[0-9]+$',
  words: '([A-Z]*[a-z]*)*',
  string: '([0-9]*[ ]*[A-Z]*[a-z]*[-_()/]*)*',
  point25: '^\-?[0-9]*(?:\\.25|\\.50|\\.75|)$',
  date: '^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$',
  fuelString :'([0-9]*[ ]*[A-Z]*[a-z]*[-_()/%]*)*',
};