import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { OrgProfileComponent } from './modules/org-profile/org-profile.component';
import { AclGuardGuard } from './authentication/guard/acl-guard.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: 'module',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboards/dashboard.module#DashboardModule',
      },
      {
        path: 'org-profile',
        component: OrgProfileComponent
      },
      {
        path: 'sysConfig',
        loadChildren: './system-config/system-config.module#SystemConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'sysConfig' }
      },
      {
        path: 'org-profile/addUpdate/:id',
        component: OrgProfileComponent
      },
      {
        path: 'user-profile',
        loadChildren: './modules/new-user-profile/new-user-profile.module#NewUserProfileModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/userProfile' }
      },
      {
        path: 'labelConfig',
        loadChildren: './modules/label-config/label-config.module#LabelConfigModule'
      },
      {
        path: 'bom',
        loadChildren: './modules/bom/bom.module#BOMModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/bom' }
      },
      {
        path: 'case',
        loadChildren: './modules/case/case.module#CaseModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/case' }
      },
      {
        path: 'packing',
        loadChildren: './modules/packing/packing.module#PackingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/packing' }
      },
      {
        path: 'newParty',
        loadChildren: './modules/new-party/new-party.module#NewPartyModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/newParty' }
      },
      {
        path: 'asset',
        loadChildren: './modules/asset/asset.module#AssetModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/asset' }
      },
      {
        path: 'transactionMode',
        loadChildren: './modules/transaction-mode/transaction-mode.module#TransactionModeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/transactionMode' }
      },
      {
        path: 'advocate',
        loadChildren: './modules/advocate/advocate.module#AdvocateModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/advocate' }
      },
      {
        path: 'assetType',
        loadChildren: './modules/asset-type/asset-type.module#AssetTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/assetType' }
      },
      {
        path: 'company',
        loadChildren: './modules/company/company.module#CompanyModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/company' }
      },
      {
        path: 'businessAssociate',
        loadChildren: './modules/business-associate/business-associate.module#BusinessAssociateModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/businessAssociate' }
      },
      {
        path: 'businessAssociatePerson',
        loadChildren: './modules/business-associate-person/business-associate-person.module#BusinessAssociatePersonModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/businessAssociatePerson' }
      },
      {
        path: 'status',
        loadChildren: './modules/status/status.module#StatusModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/status' }
      },
      {
        path: 'godown',
        loadChildren: './modules/godown/godown.module#GodownModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/godown' }
      },
      {
        path: 'contractStatus',
        loadChildren: './modules/contract-status/contract-status.module#ContractStatusModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/contractStatus' }
      },
      {
        path: 'currentLegalStatus',
        loadChildren: './modules/current-legal-status/current-legal-status.module#CurrentLegalStatusModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/currentLegalStatus' }
      },
      {
        path: 'activity',
        loadChildren: './modules/activity/activity.module#ActivityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/activity' }
      },
      {
        path: 'contractType',
        loadChildren: './modules/contract-type/contract-type.module#ContractTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/contractType' }
      },
      {
        path: 'serviceProvider',
        loadChildren: './modules/service-provider/service-provider.module#ServiceProviderModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/serviceProvider' }
      },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
      {
        path: 'ph',
        loadChildren: './modules/ph/ph.module#PhModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/ph' }
      },
      {
        path: 'supporting-material',
        loadChildren: './modules/supporting-material/supporting-material.module#SupportingMaterialModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/supportingMaterial' }
      },
      {
        path: 'supporting-material-inward',
        loadChildren: './modules/supporting-material-inward/supporting-material-inward.module#SupportingMaterialInwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/inward' }
      },
      {
        path: 'supporting-material-allocation',
        loadChildren: './modules/supporting-material-allocation/supporting-material-allocation.module#SupportingMaterialAllocationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/supportingMaterialAllocation' }
      },
      {
        path: 'process',
        loadChildren: './modules/process/process.module#ProcessModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/process' }
      },
      {
        path: 'solubility',
        loadChildren: './modules/solubility/solubility.module#SolubilityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/solubility' }
      },
      {
        path: 'stock-param4',
        loadChildren: './modules/stock-param/stock-param4/stock-param4.module#StockParam4Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam4' }
      },
      {
        path: 'stock-param5',
        loadChildren: './modules/stock-param/stock-param5/stock-param5.module#StockParam5Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam5' }
      },
      {
        path: 'stock-param6',
        loadChildren: './modules/stock-param/stock-param6/stock-param6.module#StockParam6Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam6' }
      },
      {
        path: 'stock-param7',
        loadChildren: './modules/stock-param/stock-param7/stock-param7.module#StockParam7Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam7' }
      },
      {
        path: 'stock-param8',
        loadChildren: './modules/stock-param/stock-param8/stock-param8.module#StockParam8Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam8' }
      },
      {
        path: 'stock-param9',
        loadChildren: './modules/stock-param/stock-param9/stock-param9.module#StockParam9Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam9' }
      },
      {
        path: 'customParameters',
        loadChildren: './modules/custom-parameters/custom-parameters.module#CustomParametersModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'customParam' }
      },
      {
        path: 'sales-order',
        loadChildren: './modules/sales-order/sales-order.module#SalesOrderModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/salesOrder' }
      },
      {
        path: 'moisture',
        loadChildren: './modules/moisture/moisture.module#MoistureModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/moisture' }
      },
      {
        path: 'costHead',
        loadChildren: './modules/cost-head/cost-head.module#CostHeadModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/costHead' }
      },
      {
        path: 'purity',
        loadChildren: './modules/purity/purity.module#PurityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/purity' }

      },
      {
        path: 'department',
        loadChildren: './modules/department/department.module#DepartmentModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/department' }
      },
      {
        path: 'zone',
        loadChildren: './modules/zone/zone.module#ZoneModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/zone' }
      },
      {
        path: 'transport',
        loadChildren: './modules/transport/transport.module#TransportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/transport' }
      },
      {
        path: 'crmActivity',
        loadChildren: './modules/crm-activity/crm-activity.module#CrmActivityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/activity' }
      },
      {
        path: 'funnel',
        loadChildren: './modules/funnel/funnel.module#FunnelModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/funnel' }
      },
      {
        path: 'quotation',
        loadChildren: './modules/quotation/quotation.module#QuotationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/quotation' }
      },
      {
        path: 'funnelType',
        loadChildren: './modules/funnel-type/funnel-type.module#FunnelTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/funnelType' }
      },
      {
        path: 'funnelStage',
        loadChildren: './modules/funnel-stage/funnel-stage.module#FunnelStageModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/funnelStage' }
      },
      {
        path: 'party',
        loadChildren: './modules/party/party.module#PartyModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'party' }
      },
      {
        path: 'purchase-order',
        loadChildren: './modules/purchase-order/purchase-order.module#PurchaseOrderModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/purchaseOrder' }
      },
      {
        path: 'product',
        loadChildren: './modules/product/product.module#ProductModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/item' }
      },
      {
        path: 'planning',
        loadChildren: './modules/planning/planning.module#PlanningModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/planning' }
      },
      {
        path: 'loom-vise-daily-production',
        loadChildren: './modules/loom-vise-daily-production-log/loom-vise-daily-production-log.module#LoomViseDailyProductionLogModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/loom-vise-daily-production-log' }
      },
      {
        path: 'productType',
        loadChildren: './modules/product-type/product-type.module#ProductTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/productType' }
      },
      {
        path: 'production-batch',
        loadChildren: './modules/production-batch/production-batch.module#ProductionBatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/productionbatch' }
      },
      {
        path: 'new-production-batch',
        loadChildren: './modules/new-production-batch/new-production-batch.module#NewProductionBatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/newproductionbatch' }
      },
      {
        path: 'daily-production',
        loadChildren: './modules/daily-production/daily-production.module#DailyProductionModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/dailyProduction' }
      },
      {
        path: 'caseApprovalConfiguration',
        loadChildren: './modules/docket-approval-configuration/docket-approval-configuration.module#DocketApprovalConfigurationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/docketApprovalConfiguration' }
      },
      {
        path: 'approveDocket',
        loadChildren: './modules/approve-docket/approve-docket.module#ApproveDocketModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/approveDocket' }
      },
      {
        path: 'inward',
        loadChildren: './modules/inward/inward.module#InwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/inward' }
      },
      {
        path: 'labTest',
        loadChildren: './modules/lab-test/lab-test.module#LabTestModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/labTest' }
      },
      {
        path: 'dispatch',
        loadChildren: './modules/dispatch/dispatch.module#DispatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/dispatch' }
      },
      {
        path: 'branch',
        loadChildren: './modules/branch/branch.module#BranchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'nbfc/branch' }
      },
      {
        path: 'inward-foundary',
        loadChildren: './modules/inward-foundary/inward-foundary.module#InwardFoundaryModule',
      },
      {
        path: 'transferAndDispatch',
        loadChildren: './modules/transfer-and-dispatch/transfer-and-dispatch.module#TransferAndDispatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/transfer-and-dispatch' }
      },
      {
        path: 'pre-inward',
        loadChildren: './modules/pre-inward/pre-inward.module#PreInwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/preinward' }
      },
      {
        path: 'inward-stock',
        loadChildren: './modules/inward-stock/inward-stock.module#InwardStockModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/itemStock/stock' }
      },
      {
        path: 'materialRequest',
        loadChildren: './modules/material-request/material-request.module#MaterialRequestModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/requestApproval' }
      },
      {
        path: 'materialRequestConfig',
        loadChildren: './modules/material-request-config/material-request-config.module#MaterialRequestConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/requestApprovalConfig' }
      },
      {
        path: 'message-templates',
        loadChildren: './modules/message-template/message-template.module#MessageTemplateModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/smsTemplate' }
      },
      {
        path: 'part-code',
        loadChildren: './modules/part-code/part-code.module#PartCodeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/partCodeConfig' }
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'staff' }
      },
      {
        path: 'role',
        loadChildren: './modules/role/role.module#RoleModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'role' }
      },
      {
        path: 'aclConfig',
        loadChildren: './modules/acl-config/acl-config.module#AclConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'acl' }
      },
      {
        path: 'reports',
        loadChildren: './modules/report/report.module#ReportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/report' }
      },
      {
        path: 'neoReports',
        loadChildren: './modules/neo-report/neo-report.module#NeoReportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/neoReport' }
      },
      {
        path: 'machine',
        loadChildren: './modules/machine/machine.module#MachineModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/machine' }
      },
      {
        path: 'custom-field',
        loadChildren: './modules/custom-field/custom-field.module#CustomFieldModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'customField' }
      },
      {
        path: 'custom-module-field',
        loadChildren: './modules/custom-module-field/custom-module-field.module#CustomModuleFieldModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'moduleCustomField' }
      },
      {
        path: 'services',
        loadChildren: './modules/services/services.module#ServicesModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/services' }
      },
      {
        path: 'moduleFieldConfig',
        loadChildren: './modules/module-param-config/module-param-config.module#ModuleParamConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/formConfig' }
      },
      {
        path: 'sheet-log',
        loadChildren: './modules/sheet-log/sheet-log.module#SheetLogModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'sheetLog' }
      },
    ]
  },
];
