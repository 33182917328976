import {FormControl} from '@angular/forms';

export function requiredFileType( type1: string, type2: string, type3: string ) {
    return (control: FormControl) => {
        const file = control.value;
        if (file) {
            const extension = file.split('.')[1].toLowerCase();
            if (type1.toLowerCase() === extension.toLowerCase() ||
                type2.toLowerCase() === extension.toLowerCase() ||
                type3.toLowerCase() === extension.toLowerCase()) {

                return null;
            }

            return {
                requiredFileType: true
            };
        }

        return null;
    };
}
