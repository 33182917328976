import { RoleModal } from '../../role/modal/role-modal';
import {PartnerModel} from './partner-model';
import {DepartmentModal} from '../../department/modal/department-modal';
import {BranchModel} from '../../branch/model/branch-model';
import {BusinessAssociateModel} from '../../business-associate/model/business-associate-model';
import {BusinessAssociatePersonModel} from "../../business-associate-person/model/business-associate-person-model";

export class UserModal {
    public staffid: number;
    public firstname: string;
    public lastname: string;
    public username: string;
    public phone: string;
    public address: string;
    public password: string;
    public email: string;
    public role: RoleModal ;
    identitytype: string;
    identityid: string;
    department: DepartmentModal;
    organization: PartnerModel;
    manager: UserModal;
    agencyUserFlag: boolean = false;
    agency: BusinessAssociatePersonModel;

    // For Angular USE
    roleString: string;
    branch: Array<BranchModel>;
    agencyString: string;
}
