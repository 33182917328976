import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {environment} from "../../../../environments/environment";
import {OrgProfileModal} from "../modal/org-profile-modal";
import {Observable} from "rxjs";
import {BaseService} from '../../../core/service/base-service';
import {AuthUtilsService} from '../../../core/util/auth-utils.service';

@Injectable({
  providedIn: 'root'
})
export class OrgProfileService extends BaseService<OrgProfileModal> {

  constructor(
      http: HttpClient,
      authService: AuthUtilsService) {
    super(http, authService);
  }

  getOrgData(): Observable<ResponseData<OrgProfileModal>> {
    return this.http.get<ResponseData<OrgProfileModal>>(environment.SERVER_URL + this.getModuleName() + '/1');
  }

  getModuleName(): string {
    return 'organization';
  }

  validate(): boolean {
    return false;
  }

  updateHeaderLogo(file: File): Observable<ResponseData<OrgProfileModal>> {
    const form: FormData =  new FormData();
    form.append('file', file, file.name);
    return this.http.post<ResponseData<OrgProfileModal>>(environment.SERVER_URL + this.getModuleName() + '/updateHeaderLogo', form);
  }

  updateFooterSign(file: File): Observable<ResponseData<OrgProfileModal>> {
    const form: FormData =  new FormData();
    form.append('file', file, file.name);
    return this.http.post<ResponseData<OrgProfileModal>>(environment.SERVER_URL + this.getModuleName() + '/updateAuthSign', form);
  }
}
