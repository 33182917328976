import { RouteInfo } from './sidebar.metadata';

/*
moduleName :
    desc  : ACL use
    value : API url after /api/v1/.
    expl  : url  "http://demoao.neofruition.co.in:83/api/v1/chemical/product" => "chemical/product"
*/

export const CHEMICAL_ROUTES_LIST: RouteInfo[] = [
        {
          path: '/module/dashboard',
          title: 'Dashboard',
          titleKey: 'Dashboard',
          icon: 'mdi mdi-view-dashboard',
          class: '',
          moduleName: '',
          extralink: false,
          submenu: [],
          imgUrl : "assets/images/dashboard-icon.png",
        },
        {
          path: '',
          title: 'Masters',
          titleKey: 'Masters',
          icon: 'mdi mdi-bookmark-plus-outline',
          class: 'has-arrow',
          moduleName: '',
          imgUrl : "assets/images/masters-icon.png",
          extralink: false,
          submenu: [
            // {
            //   path: '/module/party',
            //   title: 'Party',
            //   titleKey: 'Party',
            //   moduleName: 'party',
            //   icon: 'mdi mdi-account-box-outline',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            {
              path: '/module/newParty',
              title: 'Borrower',
              titleKey: 'Borrower',
              moduleName: 'chemical/newParty',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/assetType',
              title: 'Asset Type',
              titleKey: 'Asset Party',
              moduleName: 'chemical/assetType',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/company',
              title: 'Company',
              titleKey: 'Company',
              moduleName: 'chemical/company',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/godown',
              title: 'Godown',
              titleKey: 'Godown',
              moduleName: 'chemical/godown',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/contractStatus',
              title: 'Contract Status',
              titleKey: 'Contract Status',
              moduleName: 'chemical/contractStatus',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/currentLegalStatus',
              title: 'Current Legal Status',
              titleKey: 'Current Legal Status',
              moduleName: 'chemical/currentLegalStatus',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/activity',
              title: 'Activity',
              titleKey: 'Activity',
              moduleName: 'chemical/activity',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/contractType',
              title: 'Contract Type',
              titleKey: 'Contract Type',
              moduleName: 'chemical/contractType',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/asset',
              title: 'Asset',
              titleKey: 'Asset',
              moduleName: 'chemical/asset',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/serviceProvider',
              title: 'Partner',
              titleKey: 'Partner',
              moduleName: 'chemical/serviceProvider',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/businessAssociate',
              title: 'Business Associate Type',
              titleKey: 'Business Associate Type',
              moduleName: 'nbfc/businessAssociate',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/businessAssociatePerson',
              title: 'Business Associate',
              titleKey: 'Business Associate',
              moduleName: 'nbfc/businessAssociatePerson',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            // {
            //   path: '/module/branch',
            //   title: 'Branch',
            //   titleKey: 'Branch',
            //   moduleName: 'nbfc/branch',
            //   icon: 'mdi mdi-account-box-outline',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/status',
            //   title: 'Status',
            //   titleKey: 'Status',
            //   moduleName: 'nbfc/status',
            //   icon: 'mdi mdi-account-box-outline',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/bom',
            //   title: 'BOM',
            //   titleKey: 'bom',
            //   moduleName: 'chemical/bom',
            //   icon: 'mdi mdi-basket-fill',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/product',
            //   title: 'Material',
            //   titleKey: 'Material',
            //   moduleName: 'chemical/item',
            //   icon: 'mdi mdi-package-variant',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/productType',
            //   title: 'Material Type',
            //   titleKey: 'materialType',
            //   moduleName: 'chemical/productType',
            //   icon: 'mdi mdi-package-variant',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/supporting-material',
            //   title: 'Supporting Material',
            //   imgUrl : "",
            //   titleKey: 'Supporting Material',
            //   moduleName: 'chemical/supportingMaterial',
            //   icon: 'mdi mdi-account-box-outline',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/purity',
            //   title: 'Material Property',
            //   titleKey: 'Chemical Property',
            //   moduleName: 'chemical/purity',
            //   icon: 'fas fa-vial',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/costHead',
            //   title: 'Cost Head',
            //   titleKey: 'CostHead',
            //   moduleName: 'chemical/costHead',
            //   icon: 'mdi mdi-cube-outline',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/process',
            //   title: 'Process',
            //   titleKey: 'Process',
            //   imgUrl : "",
            //   moduleName: 'chemical/process',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            {
              path: '/module/department',
              title: 'Department',
              titleKey: 'Department',
              imgUrl : "",
              moduleName: 'chemical/department',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/transactionMode',
              title: 'Mode Of Payment',
              titleKey: 'Mode Of Payment',
              moduleName: 'nbfc/transactionMode',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/advocate',
              title: 'Advocate Details',
              titleKey: 'Advocate Details',
              moduleName: 'nbfc/advocate',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            // {
            //   path: '/module/zone',
            //   title: 'Zone',
            //   titleKey: 'Zone',
            //   imgUrl : "",
            //   moduleName: 'zone',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/machine',
            //   title: 'Machine',
            //   titleKey: 'Machine',
            //   imgUrl : "",
            //   moduleName: 'chemical/machine',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/packing',
            //   title: 'Packing',
            //   titleKey: 'Packing',
            //   imgUrl : "",
            //   moduleName: 'chemical/packing',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/transport',
            //   title: 'Transport',
            //   titleKey: 'Transport',
            //   moduleName: 'chemical/transport',
            //   icon: 'mdi mdi-truck',
            //   class: '',
            //   imgUrl : "",
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/custom-field',
            //   title: 'Custom Field',
            //   titleKey: 'Custom Field',
            //   imgUrl : "",
            //   moduleName: 'customField',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/customParameters',
            //   title: 'Quality Parameters',
            //   titleKey: 'Quality Parameters',
            //   imgUrl : "",
            //   moduleName: 'customParam',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/custom-module-field',
            //   title: 'Custom Module Field',
            //   imgUrl : "",
            //   titleKey: 'Custom Module Field',
            //   moduleName: 'moduleCustomField',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/funnelStage',
            //   title: 'Funnel Stage',
            //   titleKey: 'Funnel Stage',
            //   imgUrl : "",
            //   moduleName: 'crm/funnelStage',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
            // {
            //   path: '/module/crmActivity',
            //   title: 'CRM Activity',
            //   titleKey: 'CRM Activity',
            //   imgUrl : "",
            //   moduleName: 'crm/activity',
            //   icon: 'mdi mdi-settings',
            //   class: '',
            //   extralink: false,
            //   submenu: []
            // },
          ]
        },
        // {
        //   path: '',
        //   title: 'CRM',
        //   titleKey: 'CRM',
        //   icon: 'mdi mdi-ticket-account',
        //   class: 'has-arrow',
        //   moduleName: '',
        //   imgUrl : "assets/images/dashboard-icon.png",
        //   extralink: false,
        //   submenu: [
        //     {
        //       path: '/module/funnel',
        //       title: 'Funnel',
        //       titleKey: 'Funnel',
        //       icon: 'fa fa-filter',
        //       imgUrl : "",
        //       moduleName: 'crm/funnel',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/quotation',
        //       title: 'Quotation',
        //       titleKey: 'quotation',
        //       imgUrl : "",
        //       icon: 'mdi mdi-cart-outline',
        //       moduleName: 'crm/quotation',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     }
        //   ]
        // },
        {
          path: '/module/case',
          title: 'Case Management',
          titleKey: 'Case',
          moduleName: 'nbfc/case',
          icon: 'mdi mdi-account-box-outline',
          class: '',
          imgUrl : "assets/images/settings-icon.png",
          extralink: false,
          submenu: []
        },
        {
          path: '/module/approveDocket',
          title: 'Pending Approval',
          titleKey: 'Pending Approval',
          imgUrl : 'assets/images/final-docket-approval-two.png',
          icon: 'mdi mdi-file-chart',
          class: '',
          moduleName: 'nbfc/approveDocket',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/sheet-log',
          title: 'Sheet Log',
          titleKey: 'Sheet Log',
          imgUrl : 'assets/images/final-docket-approval-two.png',
          icon: 'mdi mdi-file-chart',
          class: '',
          moduleName: 'Sheet Log',
          extralink: false,
          submenu: []
        },
        {
          path: '',
          imgUrl : 'assets/images/approval-configuration.png',
          title: 'Settings',
          titleKey: 'Settings',
          icon: 'fa fa-link',
          moduleName: '',
          class: '',
          extralink: false,
          submenu: [
                {
               path: '/module/caseApprovalConfiguration',
               title: 'Approval Configuration',
               titleKey: 'Approval Configuration',
               moduleName: 'nbfc/docketApprovalConfiguration',
               icon: 'mdi mdi-account-box-outline',
               class: '',
               imgUrl : 'assets/images/approval-configuration.png',
               extralink: false,
               submenu: []
          },
         ]
        },
        {
          path: '/module/neoReports',
          title: 'Report',
          titleKey: 'Report',
          imgUrl : "assets/images/reports-icon.png",
          icon: 'mdi mdi-file-chart',
          class: '',
          moduleName: 'chemical/neoReport',
          extralink: false,
          submenu: []
        },
  {
    path: '/module/neoReport',
    title: 'Report',
    titleKey: 'Report',
    imgUrl : "assets/images/reports-icon.png",
    icon: 'mdi mdi-file-chart',
    class: '',
    moduleName: 'chemical/report',
    extralink: false,
    submenu: []
  },
        // {
        //   path: '',
        //   title: 'Account',
        //   titleKey: 'Account',
        //   icon: 'mdi mdi-ticket-account',
        //   class: 'has-arrow',
        //   imgUrl : "assets/images/account-icon.png",
        //   moduleName: '',
        //   extralink: false,
        //   submenu: [
        //     {
        //       path: '/module/purchase-order',
        //       title: 'Purchase Order',
        //       titleKey: 'Purchase Order',
        //       imgUrl : "",
        //       icon: 'mdi mdi-truck',
        //       moduleName: 'chemical/purchaseOrder',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/sales-order',
        //       title: 'Sales Order',
        //       imgUrl : "",
        //       titleKey: 'Sales Order',
        //       icon: 'mdi mdi-cart-outline',
        //       moduleName: 'chemical/salesOrder',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     }
        //   ]
        // },
        // {
        //   path: '',
        //   title: 'Inventory',
        //   titleKey: 'Inventory',
        //   icon: 'mdi mdi-factory',
        //   imgUrl : "assets/images/inventory-icon.png",
        //   class: 'has-arrow',
        //   moduleName: '',
        //   extralink: false,
        //   submenu: [
        //     {
        //       path: '/module/inward-stock',
        //       title: 'Material Stock',
        //       titleKey: 'Material Stock',
        //       icon: 'fa fa-retweet',
        //       imgUrl : "",
        //       moduleName: 'chemical/itemStock/stock',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/supporting-material-inward',
        //       title: 'Supporting Material Inward',
        //       titleKey: 'Supporting Material Inward',
        //       icon: 'mdi mdi-beaker',
        //       imgUrl : "",
        //       moduleName: 'chemical/inward',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/materialRequest',
        //       title: 'Material Request',
        //       titleKey: 'Material Request',
        //       icon: 'fa fa-request',
        //       imgUrl : "",
        //       moduleName: 'chemical/requestApproval',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     }
        //   ]
        // },
        // {
        //   path: '/module/labTest',
        //   imgUrl : "assets/images/labtest-icon.png",
        //   title: 'Lab Test',
        //   titleKey: 'LabTest',
        //   icon: 'mdi mdi-bookmark-plus-outline',
        //   class: '',
        //   moduleName: 'chemical/labTest',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/planning',
        //   imgUrl : "assets/images/masters-icon.png",
        //   title: 'Planning',
        //   titleKey: 'Planning',
        //   icon: 'mdi mdi-chip',
        //   class: '',
        //   moduleName: 'chemical/planning',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/production-batch',
        //   imgUrl : "assets/images/productionbatch-icon.png",
        //   title: 'Production Batch',
        //   titleKey: 'ProductionBatch',
        //   icon: 'fa fa-cogs',
        //   class: '',
        //   moduleName: 'chemical/productionbatch',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/new-production-batch',
        //   imgUrl : "assets/images/productionbatch-icon.png",
        //   title: 'Production Batch',
        //   titleKey: 'NewProductionBatch',
        //   icon: 'fa fa-cogs',
        //   class: '',
        //   moduleName: 'chemical/newproductionbatch',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/daily-production',
        //   imgUrl : "assets/images/productionbatch-icon.png",
        //   title: 'Daily Production',
        //   titleKey: 'DailyProduction',
        //   icon: 'fa fa-clock-o',
        //   class: '',
        //   moduleName: 'chemical/dailyProduction',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/loom-vise-daily-production',
        //   imgUrl : "assets/images/productionbatch-icon.png",
        //   title: 'DP Log',
        //   titleKey: 'DailyProductionLog',
        //   icon: 'fa fa-file-text',
        //   class: '',
        //   moduleName: 'chemical/loom-vise-daily-production-log',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/dispatch',
        //   imgUrl : "assets/images/dispatch-icon.png",
        //   title: 'Dispatch',
        //   titleKey: 'Dispatch',
        //   icon: 'mdi mdi-truck',
        //   class: '',
        //   moduleName: 'chemical/salesOrder',
        //   extralink: false,
        //   submenu: []
        // },
        // {
        //   path: '/module/transferAndDispatch',
        //   imgUrl : "assets/images/labtest-icon.png",
        //   title: 'Job Work',
        //   titleKey: 'jobWork',
        //   icon: 'fa fa-cogs',
        //   class: '',
        //   moduleName: 'chemical/transfer-and-dispatch',
        //   extralink: false,
        //   submenu: []
        // },
        {
          path: '/module/user',
          imgUrl : "assets/images/staff-icon.png",
          title: 'Staff',
          titleKey: 'Staff',
          icon: 'mdi mdi-account',
          moduleName: 'staff',
          class: '',
          extralink: false,
          submenu: []
        },
        // {
        //   path: '',
        //   imgUrl : "assets/images/settings-icon.png",
        //   title: 'Settings',
        //   titleKey: 'Settings',
        //   icon: 'fa fa-link',
        //   moduleName: '',
        //   class: '',
        //   extralink: false,
        //   submenu: [
        //     {
        //       path: '/module/message-templates',
        //       title: 'Message Templates',
        //       titleKey: 'Message Templates',
        //       icon: 'fa fa-retweet',
        //       imgUrl : "",
        //       moduleName: 'chemical/smsTemplate',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/part-code',
        //       title: 'Product Code',
        //       titleKey: 'Product Code',
        //       icon: 'fa fa-retweet',
        //       imgUrl : "",
        //       moduleName: 'chemical/partCodeConfig',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/materialRequestConfig',
        //       title: 'Material Request Configuration',
        //       titleKey: 'Material Request Configuration',
        //       icon: 'fa fa-retweet',
        //       imgUrl : "",
        //       moduleName: 'chemical/requestApprovalConfig',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     },
        //     {
        //       path: '/module/moduleFieldConfig',
        //       title: 'Module Param Config',
        //       titleKey: 'Module Param Config',
        //       icon: 'fa fa-retweet',
        //       imgUrl : "",
        //       moduleName: 'chemical/formConfig',
        //       class: '',
        //       extralink: false,
        //       submenu: []
        //     }
        //   ]
        // },
        {
          path: '/module/aclConfig',
          imgUrl : "assets/images/ACLconfig-icon.png",
          title: 'ACL Config',
          titleKey: 'ACL Config',
          icon: 'mdi mdi-account',
          moduleName: 'acl',
          class: '',
          extralink: false,
          submenu: []
        }
      ];
