import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './component/util/pagination/pagination.component';
import { HeaderComponent } from './component/header/header/header.component';
import { TranslatePipe } from './pipe/translate/translate.pipe';
import { FormsModule } from '@angular/forms';
import { StockHeaderComponent } from './component/header/stock-header/stock-header.component';
import { FilterPipePipe } from './pipe/filter-pipe.pipe';
import { FormatDatePipe } from './pipe/format-date/format-date.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {DateFormatPipe} from './pipe/dateFormat/date-format.pipe';
import {NumberFormatPipe} from './pipe/number-formate/number-format.pipe';
import { NumberToWordPipe } from './pipe/number-to-word.pipe';
import { LabelConfigPipe } from './pipe/labelConfig/label-config.pipe';
import {AppDirectivesModule} from "../app-derectives/app-derectives.module";
import { IndianRupeesNumberFormatPipe } from './pipe/IndianRupeesNumberFormat/indian-rupees-number-format.pipe';

@NgModule({
  declarations: [
    PaginationComponent,
    HeaderComponent,
    TranslatePipe,
    StockHeaderComponent,
    FilterPipePipe,
    FormatDatePipe,
    DateFormatPipe,
    NumberFormatPipe,
    NumberToWordPipe,
    LabelConfigPipe,
    IndianRupeesNumberFormatPipe
  ],
    imports: [
        CommonModule,
        FormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        AppDirectivesModule
    ],
    exports: [
        PaginationComponent,
        HeaderComponent,
        StockHeaderComponent,
        TranslatePipe,
        FilterPipePipe,
        FormatDatePipe,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        DateFormatPipe,
        NumberFormatPipe,
        NumberToWordPipe,
        NumberToWordPipe,
        LabelConfigPipe,
        IndianRupeesNumberFormatPipe
    ]
})
export class CoreModule { }
