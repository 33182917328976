export class OrgProfileModal {
    accountHolderName: string;
    accountNumber: number;
    additionalNameForInvoice: string;
    authorisedSignatury: string;
    bankName: string;
    billingAddress:	string;
    branchName:	string;
    companyName: string;
    footerNote:	string;
    footerSign:	string;
    gstin: string;
    headerLogo: string;
    id: number;
    ifscCode: string;
    pan: string;
    shippingAddress: string;
    phoneNo: number;
    mobileNo: number;
    email: string;
    secondaryMobileNo: number;
}
