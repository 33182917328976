import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule
} from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CoreModule } from './core/core.module';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TranslateService } from './core/service/translate/translate.service';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { QuillModule } from 'ngx-quill';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {OrgProfileComponent} from "./modules/org-profile/org-profile.component";
import {LocalDataStorageService} from './core/service/local-data-storage.service';
import {LabelConfigPipe} from './core/pipe/labelConfig/label-config.pipe';
import {ConfirmDialogBoxModule} from './shared/confirm-dialog-box/confirm-dialog-box.module';
import {ConfirmationDialogComponent} from './shared/confirm-dialog-box/component/confirmation-dialog/confirmation-dialog.component';
import {SystemConfigService} from "./system-config/service/system-config.service";
import {ModuleParamGlobConfigService} from "./core/service/moduleFieldConfig/module-param-glob-config.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    OrgProfileComponent,
  ],
  imports: [
    QuillModule,
    CommonModule,
    BrowserModule,
    HttpModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    CoreModule,
    RouterModule.forRoot(Approutes),
    ToastrModule.forRoot(),
    PerfectScrollbarModule,
    NgHttpLoaderModule.forRoot(),
    ConfirmDialogBoxModule,
    RouterModule.forRoot(Approutes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,

    }, TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN'},
    LocalDataStorageService,
    LabelConfigPipe,
    SystemConfigService,
    ModuleParamGlobConfigService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
      ConfirmationDialogComponent
  ]
})
export class AppModule { }

export function setupTranslateFactory(
    service: TranslateService): Function {
  return () => service.use('en');
}
