import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AclDataModal} from '../../shared/sidebar/acl-data-modal';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AclGuardGuard implements CanActivate {

  constructor(private nativeRouter :Router,private toastar : ToastrService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
      : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let routeData : Data = route.data;

    try{
      let aclModule : Map<string,AclDataModal> = new Map<string,AclDataModal>();
      let aclDataString = localStorage.getItem(environment.ACL_LOCAL_KEY);
      if(aclDataString){
        aclModule = new Map(JSON.parse(aclDataString));
        let serviceName : string = routeData.moduleName;
        let acl : AclDataModal = aclModule.get(serviceName);

        if(acl && acl.view && acl.view == 1){
          return true;
        }

        this.toastar.warning("You are not allow to access this module","Access Denied")
        this.nativeRouter.navigate(["/module"]);
      }
    }catch (e) {
      console.error("Error in initAcl",e);
    }

    return false;
  }
  
}
