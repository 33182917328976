import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { RequestData } from "../modal/base-save-update-modal";
import { AuthUtilsService } from "../util/auth-utils.service";
import { IBaseService } from "./ibase-service";
import { ResponseData } from "../modal/base-save-update-response";
import { PaginationData } from "../modal/pagination-data";
import { GetAllRequest } from "../modal/get-all-request";
import { OrderingData } from "../modal/ordering-data";


export abstract class BaseService<T> implements IBaseService<T>{

    constructor(public http: HttpClient, public authService: AuthUtilsService) {
    }
    save(modal: T): Observable<ResponseData<T>> {
        let reqObj: RequestData<T> = new RequestData<T>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        // if (environment.moduleName == 'textile') {
        //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/save", reqObj.data);
        // } else {
            return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/save", reqObj.data);
        // }
    }

    saveWithImage(modal: T, files: Array<File>): Observable<ResponseData<T>> {
        let reqObj: RequestData<T> = new RequestData<T>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        const uploadFormData = new FormData();
        files.forEach(
            (file) => {
                uploadFormData.append("file", file, file.name);
            }
        );
        uploadFormData.append("entityDTOString", "" + JSON.stringify(reqObj.data));
        //uploadFormData.append("entityDTOString","" + JSON.stringify(reqObj));
        return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/save", uploadFormData);
    }

    updateWithImage(modal: T, files: Array<File>): Observable<ResponseData<T>> {
        let reqObj: RequestData<T> = new RequestData<T>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        const uploadFormData = new FormData();
        files.forEach(
            (file) => {
                uploadFormData.append("file", file, file.name);
            }
        );
        uploadFormData.append("entityDTOString", "" + JSON.stringify(reqObj.data));
        // uploadFormData.append("uploadingFiles", file, file.name);
        // uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
        return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/update", uploadFormData);
    }

    updateImage(foreinKey: number, moduleName: string, file: File): Observable<ResponseData<{ foreinKey: number, moduleName: string, imagePath: string, id: number }>> {
        let reqObj: RequestData<{ foreinKey: number, moduleName: string }> = new RequestData<{ foreinKey: number, moduleName: string }>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = { foreinKey: foreinKey, moduleName: moduleName };
        const uploadFormData = new FormData();
        uploadFormData.append("uploadingFiles", file, file.name);
        uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
        return this.http.post<ResponseData<{ foreinKey: number, moduleName: string, imagePath: string, id: number }>>(environment.SERVER_URL + this.getModuleName() + "/updateWithFile", uploadFormData);
    }

    saveImage(foreinKey: number, moduleName: string, file: File): Observable<ResponseData<{ foreinKey: number, moduleName: string, imagePath: string, id: number }>> {
        let reqObj: RequestData<{ foreinKey: number, moduleName: string }> = new RequestData<{ foreinKey: number, moduleName: string }>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = { foreinKey: foreinKey, moduleName: moduleName };
        const uploadFormData = new FormData();
        uploadFormData.append("uploadingFiles", file, file.name);
        uploadFormData.append("bodyData", "" + JSON.stringify(reqObj));
        return this.http.post<ResponseData<{ foreinKey: number, moduleName: string, imagePath: string, id: number }>>(environment.SERVER_URL + "filesData/saveWithFile", uploadFormData);
    }

    update(modal: T): Observable<ResponseData<T>> {
        let reqObj: RequestData<T> = new RequestData<T>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        // if (environment.moduleName == 'textile') {
        //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/update", reqObj.data);
        // } else {
            return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/update", reqObj.data);
        // }
    }

    saveOrUpdate(modal: T): Observable<ResponseData<T>> {
        return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/saveOrUpdate", modal);
    }
    saveOrUpdateAll(modal: Array<T>): Observable<ResponseData<T>> {
        return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/saveOrUpdateAll", modal);
    }
    delete(modal: T): Observable<ResponseData<T>> {
        let reqObj: RequestData<T> = new RequestData<T>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        // if (environment.moduleName == 'textile') {
        //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
        // } else {
            return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
        // }
    }
    deleteMulti(modal: Array<T>): Observable<ResponseData<T>> {
        let reqObj: RequestData<Array<T>> = new RequestData<Array<T>>();
        reqObj.authToken = this.authService.getAuthToken();
        reqObj.requestToken = this.getRequestToken();
        reqObj.data = modal;
        // if (environment.moduleName == 'textile') {
        //     return this.http.post<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/delete", reqObj.data);
        // } else {
        return this.http.post<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/deleteAll", reqObj.data);
        // }
    }
    getById(id: number): Observable<ResponseData<T>> {
        // if (environment.moduleName == 'textile') {
        //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName() + "/" + id);
        // } else {
            return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/" + id);
        // }
    }
    getAll(paginationData: PaginationData, orderingData: OrderingData): Observable<ResponseData<T>> {
        let requestData: GetAllRequest = new GetAllRequest();
        requestData.data = { paginationData: paginationData, orderingData: orderingData };
        // if (environment.moduleName == 'textile') {
        //     return this.http.get<ResponseData<T>>(environment.Textile_SERVER_URL + this.getModuleName());
        // } else {
            return this.http.get<ResponseData<T>>(environment.SERVER_URL + this.getModuleName() + "/getAllByPartner");
        // }
    }

    getRequestToken(): string {
        return "xyz1234";
    }
    abstract validate(): boolean;
    abstract getModuleName(): string;
    // abstract  getId(): number;

}
