import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(Object.keys(environment).indexOf("NUMBER_FORMAT") >= 0){
      return super.transform(value, JSON.parse(JSON.stringify(environment))["NUMBER_FORMAT"]) ;
    }

    return super.transform(value,"1.0-2");

    }

}
