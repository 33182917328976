import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianRupeesNumberFormat'
})
export class IndianRupeesNumberFormatPipe implements PipeTransform {

  transform(value: number, args?: any): any {
      if (! isNaN(value)) {
        value =  parseFloat(''  + Number(value).toFixed(2));
        const currencySymbol = '₹';
        const result = value.toString().split('.');

        let lastThree = result[0].substring(result[0].length - 3);
        const otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

        if (result.length > 1) {
          output += '.' + result[1];
        }

        return output;
      }
  }

}
