import { Pipe, PipeTransform } from '@angular/core';
import { environment } from "../../../../environments/environment";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'dateFormat'
})


export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(Object.keys(environment).indexOf("DATE_FORMAT") >= 0){
      return super.transform(value, JSON.parse(JSON.stringify(environment))["DATE_FORMAT"]);
    }

    return super.transform(value, 'dd-MM-yyyy');

  }
}
