import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {

  transform(items: any, searchText: string, key?: string): any {
      if (!items) {
          return null;
      }
      if (!searchText) {
          return items;
      }

      searchText = searchText.toLowerCase();

      if (!key) {
          return items.filter((it) => {
              return JSON.stringify(it).toLowerCase().includes(searchText.trim());
          });
      }

      return items.filter((it) => {
          const splitKey: string[] = key.split('.');
          if (splitKey && splitKey.length === 2) {
              return it[splitKey[0]][splitKey[1]].toLowerCase().includes(searchText.trim());
          }
          return it[key].toLowerCase().includes(searchText.trim());
      });
  }
}


